import React, {useEffect, Fragment, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import Loader from "../components/loader";
import Countdown from "react-countdown";
import {sleep} from "../helpers";

function PreQueue({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();
  const [delay, setDelay] = useState(0);

  const {fetchQueue, queueData, eventData, locale, t} = DeviceStore;

  useEffect(() => {

    if(!queueData.status){
      checkQueue();
    }
    const timer = setInterval(checkQueue, 30000);
    return () => {
      clearInterval(timer);
    }
  }, []);

  useEffect(() => {
    document.title = t('queue');
  }, [locale])

  const onComplete = () => {
    const min = 0;
    const max = 5000;
    setDelay(Math.random() * (max - min) + min);
    checkQueue();
  }

  const checkQueue = async () => {
    await sleep(delay);
    await fetchQueue(shop_id);
    const {queueData} = DeviceStore;
    if(queueData.status === 'session'){
      navigate(`/${organisation_slug}/${shop_id}`)
    }
    if(queueData.status === 'queue'){
      navigate(`/${organisation_slug}/${shop_id}/queue`)
    }
  }

  if(!queueData.status){
    return <Loader/>
  }

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {

    if(completed){
      return t('you_will_be_redirected');
    }

    return t('starts_over') + ' ' +minutes+' ' + (minutes === 1 ? t('minute') : t('minutes')) + ' '+t('and')+' '+seconds+' '+t('seconds')+'.';
  }

  const eventLoaded = !!eventData;

  const renderInfo = () => {
    if(locale === 'nl'){
      return (
          <Fragment>
            <p>Welkom in de wachtrij voor het kopen van je tickets voor <strong>{queueData.event_name}</strong>.</p>
          </Fragment>
      )
    }
    return (
        <Fragment>
          <p>Welcome to the queue to buy your tickets for <strong>{queueData.event_name}</strong>.</p>
        </Fragment>
    )
  }

  return (
      <div className={'space-y-4 ' + (eventLoaded ? '' : '  !mt-4 !lg:mt-10')}>

        <div className={'bg-white dark:bg-gray-900 p-4 space-y-4 rounded-md dark:text-white ' + (eventLoaded ? '' : 'shadow-md p-8')}>
          {/*<h1 className="text-2xl font-bold mb-2">Wachtrij</h1>*/}

          { queueData.message ? <p className="bg-blue-600 text-white p-4 rounded-md">
            {queueData.message}
          </p> : null }

          {renderInfo()}

          <p><Countdown date={queueData.preQueueTime * 1000} renderer={renderCountdown} onComplete={onComplete}/></p>

          {queueData.state === 'paused' ? <p className="font-bold">{t('queue_paused')}</p> : null}

          { queueData.numberInLine ? <p>
            {t('people_waiting')} <strong className="text-lg">{Math.max(queueData.numberInLine || 0, 1)}</strong>.<br/>
          </p> : null }
        </div>

      </div>
  );

}

export default inject("DeviceStore")(observer(PreQueue));
