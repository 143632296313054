import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";

function Redirect({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if(!DeviceStore.redirect){
      navigate(`/${organisation_slug}/${shop_id}`)
    }
  }, []);

  return (
      <div className="bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 p-4 dark:bg-gray-800 dark:text-white dark:border-gray-700">
        <a target="_top" href={DeviceStore.redirect}><span className="text-blue-600 underline">Klik hier</span> indien je niet automatisch wordt doorgestuurd...</a>
      </div>
  );

}

export default inject("DeviceStore")(observer(Redirect));
