import React, {useEffect} from "react";
import {observer, useLocalObservable} from "mobx-react-lite";
import {inject} from "mobx-react";
import {useParams, useNavigate, Link} from "react-router-dom";
import Loader from '../components/loader';
import TicketRow from '../components/ticketRow';
import Category from '../components/category';
import Seatplan from "../components/seatplan";
import TicketVariants from "../components/TicketVariants";
import { useSpring, animated } from '@react-spring/web'
import Timer from "../components/timer";
import PrimaryButton from "../components/PrimaryButton";
import Countdown from 'react-countdown';
import ErrorView from "../components/errorView";
import {pad, sleep} from "../helpers";
import WaitingListBanner from "../components/WaitingListBanner";

function Event({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();
  const {fetchQueue} = DeviceStore;

  const state = useLocalObservable(() => ({
    loading: false,
    contact: false,
    btnLoading: false,
    seatplanTicket: null,
    variantTicket: null,
    error: false,
  }));

  const springs = useSpring({
    from: { x: 25, opacity: 0, },
    to: { x: 0, opacity: 1 },
  })

  useEffect(() => {
    init();
    DeviceStore.setStep(1);
  }, []);

  /**
   * @returns {Promise<boolean>}
   */
  const checkQueue = async () => {

    const {queueData} = DeviceStore;

    if (queueData.status !== 'session') {
      await fetchQueue(shop_id, 'error');

      const {queueData} = DeviceStore;

      if(queueData.status === 'pre-queue'){
        navigate('pre-queue')
        return true;
      }

      if (queueData.status === 'queue') {
        navigate('queue')
        return true;
      }
      if(queueData.status === 'error'){
        state.error = true;
        return true;
      }
    }

    return false;
  }

  const init = async (countdown = false) => {
    if(!DeviceStore.eventData) {
      state.loading = true;
    }

    DeviceStore.setClientId(shop_id);

    await sleep(countdown ? 5000 : 100);

    const queueing = await checkQueue();

    if(queueing){
      return;
    }

    const success = await DeviceStore.getEvent(organisation_slug, shop_id, !!DeviceStore.eventData);

    if(!success){
      state.error = true;
    }

    DeviceStore.setTime();

    // if(DeviceStore.eventData && DeviceStore.eventData.valid_invite === false){
    //   navigate('invite')
    // }

    state.loading = false;
  }

  const chooseSeats = (ticket) => {
    state.seatplanTicket = ticket;
  }

  const chooseVariants = (ticket) => {
    state.variantTicket = ticket;
  }

  const closeSeatplan = () => {
    state.seatplanTicket = null;
  }

  const closeTicketVariant = () => {
    state.variantTicket = null;
  }

  const onCountdownEnded = async () => {
    await init(true);
  }

  if(state.error){
    return <ErrorView/>;
  }

  if(state.loading || !DeviceStore.eventData){
    return (<Loader/>)
  }

  const {t, locale} = DeviceStore;

  const {event, dates, notice, categories, pay_methods} = DeviceStore.eventData;
  let tickets = DeviceStore.getTicketTypes;

  const {time, defaultLocale, theme} = DeviceStore;

  const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {

    if(completed){
      return t('you_will_be_redirected');
    }

    if(days) {
      return t('starts_over') + ' ' + days + ' '+(days === 1 ? t('day') : t('days'))+', '+hours+' '+(hours === 1 ? t('hour') : t('hours'))+' '+t('and')+' '+minutes+' ' + (minutes === 1 ? t('minute') : t('minutes')) + '.';
    }

    if(hours < 1){
      return <span>{t('starts_over')} <strong>{minutes}:{pad(seconds, 2)}</strong> {t('minutes')}</span>
    }

    return t('starts_over') + ' ' + hours+' '+(hours === 1 ? t('hour') : t('hours'))+', '+minutes+' ' + (minutes === 1 ? t('minute') : t('minutes')) + ' '+t('and')+' '+seconds+' '+t('seconds')+'.';
  }

  const renderTicketSelect = () => {

    if(DeviceStore.eventData.invite_only && !DeviceStore.eventData.valid_invite){
      return (
          <div>
            <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700 whitespace-pre-line">
              {t('invite_only_shop')}
              <div><br/><Link to={'invite'} className="underline" href="#">{t('click_here')}</Link> {t('received_code')}</div>
            </div>
          </div>
      )
    }

    if(event.sell_end < time){
      return (
          <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700">
            {t('sale_stopped')}
          </div>
      )
    }

    if(time && event.sell_start > time){
      if(notice) {
        return (
            <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700" dangerouslySetInnerHTML={{__html: notice.replace('<br/>', '')}}>
            </div>
        )
      }

      if(event.countdown_timer) {

        return (
            <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700">
              <Countdown date={event.sell_start * 1000} renderer={renderCountdown} onComplete={onCountdownEnded}/>
            </div>
        )
      }
      return (
          <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700">
            {t('not_started')}
          </div>
      )
    }

    if(event.type === 'recurring' && !DeviceStore.date){

      if(dates.length === 0){
        return (
            <div className="p-4 py-8 border-b border-gray-200 text-gray-600 text-lg text-center dark:text-white dark:border-gray-700">
              {t('no_available_dates')}
            </div>
        )
      }

      return (
          <div>
            <div className="md:grid grid-cols-2 gap-2 p-2 space-y-2 md:space-y-0">
              {dates.map(date => {
                return (
                    <button className="block w-full border rounded p-4 text-blue-600 font-bold bg-gray-50 shadow dark:bg-gray-950 dark:border-gray-700" style={{color: theme.primary_color}} key={date.id} onClick={() => DeviceStore.setDate(date.id)}>
                      {date.label}
                      {date.time ? <span className="font-normal"> - { date.time } uur</span> : null }
                    </button>
                )
              })}
            </div>
          </div>
      )
    }

    if(!tickets.length){

      return (
          <div>
            {DeviceStore.date ? <div className="p-2 border-b md:flex justify-between items-center">
              <div className="text-sm text-blue-600 cursor-pointer flex items-center" style={{color: theme.primary_color}} onClick={() => DeviceStore.setDate(null)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                </svg>
                {t('choose_other_date')}
              </div>
              <div>
                <span className="text-xs dark:text-white">{t('chosen_date')}</span> <span className="text-blue-600 pr-1"  style={{color: theme.primary_color}}>{DeviceStore.eventData.dates.find(d => d.id === DeviceStore.date).label}</span>
              </div>
            </div>: null }
            <div className="p-4 py-8 border-b border-gray-200 dark:border-gray-700 text-gray-600 dark:text-gray-200 text-lg text-center">
              {t('no_tickets_found')}
            </div>
          </div>
      )
    }

    return (
        <div>
          {DeviceStore.date ? <div className="p-2 border-b md:flex justify-between items-center">
            <div className="text-sm text-blue-600 cursor-pointer flex items-center" style={{color: theme.primary_color}} onClick={() => DeviceStore.setDate(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
              </svg>
              {t('choose_other_date')}
            </div>
            <div>
              <span className="text-xs dark:text-white">{t('chosen_date')}</span> <span className="text-blue-600 pr-1"  style={{color: theme.primary_color}}>{DeviceStore.eventData.dates.find(d => d.id === DeviceStore.date).label}</span>
            </div>
          </div>: null }

          {categories.filter(c => {
            return tickets.filter(t => t.category_id === c.id).length > 0;
          }).map(cat => <Category key={cat.id} category={cat} chooseSeats={chooseSeats} chooseVariants={chooseVariants} tickets={tickets.filter(t => t.category_id === cat.id)}/>)}

          {categories.length && tickets.filter(t => !t.category_id).length ?
              <Category category={{name: t('other_tickets'), description: ''}} chooseSeats={chooseSeats} chooseVariants={chooseVariants} tickets={tickets.filter(t => !t.category_id)}/>
              :
              <div>
                {tickets.filter(t => !t.category_id).map(ticket => <TicketRow key={ticket.id} chooseVariants={chooseVariants} chooseSeats={chooseSeats} ticket={ticket}/>)}
              </div>
          }


        </div>
    )
  }

  const next = async () => {
    const {entranceShop, eventData, selectedTickets, getTicketTypes} = DeviceStore;

    if(eventData.event.tickets_required){
      const types = getTicketTypes;
      let hasTickets = false;
      selectedTickets.forEach(ticket => {
        const type = types.find(t => t.id === ticket.tickettype_id)
        if(!type.voucher){
          hasTickets = true;
        }
      })

      if(!hasTickets){
        DeviceStore.error = 'Je kunt alleen vouchers bestellen in combinatie met toegangstickets.'
        return;
      }

    }

    state.btnLoading = true;
    // if(!DeviceStore.reservation) {
    const result = await DeviceStore.reserve();
    if(result) {
      navigate(entranceShop ? 'check' : 'registration');
    }
    // } else {
    //   navigate(entranceShop ? 'check' : 'registration');
    // }
    state.btnLoading = false;
    if(DeviceStore.iframe) {
      window.scrollTo(0, 0);
      window.parent.postMessage({
        type: 'scrollTop',
      }, "*");
    }
  }

  const description = event.description ? (event.description[locale] ?? event.description[defaultLocale]) : null;
  const donation_description = event.donation_description ? (event.donation_description[locale] ?? event.donation_description[defaultLocale]) : null;

  let transactionFee = null;
  pay_methods.forEach(method => {
    if(transactionFee === null || method.costs.fixed * 1.21 < transactionFee && !method.costs.variable){
      transactionFee = method.costs.fixed * 1.21;
    }
  })

  return (
      <div>
        {state.variantTicket ? <TicketVariants ticket={state.variantTicket} close={closeTicketVariant}/> : null}
        {state.seatplanTicket ? <Seatplan ticket={state.seatplanTicket} close={closeSeatplan} /> : null}
        <animated.div style={springs}>

          { !!description && <div className="bg-white rounded-lg shadow-md mb-4 border border-gray-200 event-description text-gray-700 text-sm md:text-md p-4 space-y-3 dark:border-gray-700 dark:bg-gray-950 dark:text-white" dangerouslySetInnerHTML={{__html: description}}/>}

          { DeviceStore.eventData.waiting_list?.enabled ? <WaitingListBanner theme={theme}/> : null}

          <div className="bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 dark:bg-gray-900 dark:border-gray-700">

            <div>
              {renderTicketSelect()}
            </div>

            {event.donations && time && event.sell_start <= time ? <div>

              {categories.length ? <div className="p-4 border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"><h4 className="text-lg text-gray-900"> </h4><p className="text-xs text-gray-600"></p></div> : null }

              <div className="border-b p-4 dark:border-gray-700">
                <div className="flex justify-between items-center">
                  <div>
                    <label className="font-bold text-gray-800 text-sm md:text-md lg:text-lg pr-4 dark:text-gray-200">{t('donation')}</label>
                    {donation_description ? <p className="text-xs text-gray-600 pr-4 dark:text-gray-400">{donation_description}</p> : null }
                  </div>
                  <div>
                    <span className="absolute mt-2 ml-2.5 sm:text-md border-t border-transparent pointer-events-none dark:text-white">€</span>
                    <input type="number" name="gift" style={{width: '128px'}} value={DeviceStore.donation} onChange={({target}) => DeviceStore.setDonation(target.value)} className="block pl-6 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-md border-gray-300 rounded-md dark:border-gray-700 dark:bg-gray-950 dark:text-white" placeholder="0,00" min="0" step="0.01"/>
                  </div>
                </div>
              </div>

            </div> : null}

            <div className="bottom-nav p-4 flex justify-between items-center bg-gray-50 dark:bg-gray-800 dark:text-white">
              <div>
                <p className="total-label">
                  <span className="text-xs">{t('total')}:</span>&nbsp;
                  <span className="font-bold text-xl">
                      {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(DeviceStore.totalPrice)}
                    </span>
                </p>
                { event.customer_transaction_fee && DeviceStore.totalPrice && transactionFee ? <p className="text-xs text-gray-500">{t('ex_transaction_fee')} {new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(transactionFee)}</p> : null }
              </div>
              <div>
                <div className="flex">
                  <Timer/>
                  <PrimaryButton theme={theme} label={t('next')} onClick={next} disabled={ !DeviceStore.totalTickets && DeviceStore.donation < 1 || DeviceStore.donation < 0 } loading={state.btnLoading || DeviceStore.loading}/>
                </div>
              </div>
            </div>

          </div>
        </animated.div>
      </div>
  )

}

export default inject('DeviceStore')(observer(Event));
