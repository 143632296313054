import React from 'react';
import {observer} from "mobx-react-lite";
import {inject} from "mobx-react";
import {animated, Controller} from "@react-spring/web";

function Error ({DeviceStore}){

  const {error} = DeviceStore;

  let message = error;

  if(error === 'not available'){
    message = 'Een van je gekozen tickets is helaas niet meer beschikbaar.';
  }

  const animations = new Controller({ opacity: 0, y: 25 })

  const close = () => {
    animations.start({opacity: 0, scale: 0.5}).then(() => {
      DeviceStore.setError()
    })
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (!message) return null;

  animations.start({opacity: 1, y: 0})

  return (
      <div className="fixed w-full flex justify-center px-5 bottom-5 sm:bottom-16 pointer-events-none" style={{zIndex: 999999999999999999}}>
        <animated.div style={animations.springs} className="rounded-md bg-red-500 p-3 shadow-lg pointer-events-auto">
          <div className="flex items-center">
            <div className="flex-shrink-0 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

            </div>
            <div className="ml-3">
              <p className="text-md font-bold text-white">{capitalizeFirstLetter(DeviceStore.error)}</p>
            </div>
            <div className="ml-auto pl-3">
              <div className="">
                <button
                    onClick={close}
                    type="button"
                    className="inline-flex rounded-md bg-red-50/10 p-1.5 text-white hover:bg-red-400 focus:outline-none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
  )
}

export default inject("DeviceStore")(observer(Error))
