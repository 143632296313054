import React, {useEffect, Fragment} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import Loader from "../components/loader";

function Queue({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();

  const {fetchQueue, queueData, eventData, locale, t} = DeviceStore;

  useEffect(() => {

    if(!queueData.status){
      checkQueue();
    } else {
      setTimeout(checkQueue, 30000);
    }
  }, []);

  useEffect(() => {
    document.title = t('queue');
  }, [locale])

  const checkQueue = async () => {
    await fetchQueue(shop_id);
    const {queueData} = DeviceStore;

    let timeout = 40000;

    if(queueData.state === 'paused' || queueData.numberInLine === 0){
      timeout = 30000;
    } else if(queueData.estimatedTime <= 2){
      timeout = 10000;
    } else if(queueData.estimatedTime <= 4){
      timeout = 20000;
    }

    if(queueData.status === 'session'){
      navigate(`/${organisation_slug}/${shop_id}`)
    } else {
      setTimeout(checkQueue, timeout);
    }
  }

  if(!queueData.status){
    return <Loader/>
  }

  const eventLoaded = !!eventData;

  const renderInfo = () => {
    if(locale === 'nl'){
      return (
          <Fragment>
            <h1 className="text-xl font-bold">Automatische wachtrij</h1>
            <p>Welkom in de wachtrij voor het kopen van je tickets{queueData.event_name ? <span> voor <strong>{queueData.event_name}</strong></span> : null}, Het is momenteel erg druk!</p>

            <p><strong>Ververs de pagina niet: </strong> Het vernieuwen van de pagina helpt niet om eerder aan de beurt te komen; integendeel, bij overmatig gebruik kan dit leiden tot blokkering.</p>

            <p><strong>Houd je gegevens bij de hand</strong>: Zorg ervoor dat je alle benodigde informatie klaar hebt staan. Dit helpt om het proces soepel te laten verlopen. </p>

            <p><strong>Blijf verbonden</strong>: Blijf online en houd je apparaat gereed. Zodra het jouw beurt is, willen we ervoor zorgen dat je direct kunt doorgaan naar de volgende stap.</p>
          </Fragment>
      )
    }
    return (
        <Fragment>
          <h1 className="text-xl font-bold">Automatic queue</h1>
            <p>Welcome to the queue for purchasing your tickets {queueData.event_name ? <span>for <strong>{queueData.event_name}</strong></span> : null}, it's currently very busy!</p>

            <p><strong>Don't refresh the page: </strong>Refreshing the page does not help to get ahead in line earlier; in fact, if you do this too often, you will be blocked.</p>

            <p><strong>Keep your information handy</strong>: Make sure you have all the necessary information ready. This helps to keep the process smooth.</p>

            <p><strong>Stay connected</strong>: Remain online and keep your device ready. Once it's your turn, we want to ensure you can proceed to the next step immediately.</p>
         </Fragment>
    )
  }

  return (
      <div className={'space-y-4 ' + (eventLoaded ? '' : '  !mt-4 !lg:mt-10')}>
        <div className={'bg-white dark:bg-gray-900 p-4 rounded-md dark:text-white ' + (eventLoaded ? '' : 'shadow-md p-8')}>
          {/*<h1 className="text-2xl font-bold mb-2">Wachtrij</h1>*/}

          <div className="space-y-4">
            { queueData.message ? <p className="bg-blue-600 text-white p-4 rounded-md">
              {queueData.message}
            </p> : null }

            {renderInfo()}
          </div>

          {queueData.state === 'paused' ? <p className="font-bold">{t('queue_paused')}</p> :
              <p className={queueData.numberInLine || queueData.estimatedTime ? 'mt-4' : 'h-0'}>
                { queueData.numberInLine ? <span>{t('people_waiting')} <strong className="text-lg">{queueData.numberInLine}</strong>.</span> : null }<br/>
                { queueData.estimatedTime ? <span>{t('estimated_wait_time')} <strong className="text-lg">{queueData.estimatedTime} {queueData.estimatedTime === 1 ? t('minute') : t('minutes')}</strong></span> : null }
              </p>
          }
        </div>

      </div>
  );

}

export default inject("DeviceStore")(observer(Queue));
