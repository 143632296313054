import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";

function Modal({DeviceStore, children, visible, onClose, theme}){

  const {t} = DeviceStore;

  if(!visible){
    return null;
  }

  return (
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 dark:bg-opacity-75 z-50 overflow-scroll fadeIn">
        <div className="absolute top-0 right-0 bottom-0 left-0" onClick={onClose}></div>
        <div>
          <div className="px-4">
            <div className="bg-white dark:bg-gray-900 dark:border-gray-700 mx-auto rounded-md border shadow w-full max-w-2xl relative mt-4 sm:mt-14 mb-10 slideUp">
              {children}
              <div className="p-4">
                <button type="button" onClick={onClose} className={'p-2 px-3 font-bold text-sm rounded-md bg-gray-200 text-gray-900 flex w-full justify-between items-center dark:bg-gray-700 dark:text-gray-100'}>
                  <span>{t('close')}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )

}

export default inject("DeviceStore")(observer(Modal));
