import React from "react";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import Countdown from 'react-countdown';
import {pad} from "../helpers";

function Timer({DeviceStore}){

  const {reservation, checkReservation} = DeviceStore;

  if(!reservation){
    return null;
  }

  const renderTime = ({ minutes, seconds }) => {
    return <span>{minutes}:{pad(seconds, 2)}</span>
  }

  return (
      <div>
        <div className="reservation-timer bg-white flex justify-center items-center font-medium w-16 h-10 text-center rounded border mr-2 dark:bg-gray-900 dark:border-gray-700 dark:text-white">
          <Countdown date={reservation.expires * 1000} renderer={renderTime} onComplete={checkReservation}/>
        </div>
      </div>
  )

}

export default inject("DeviceStore")(observer(Timer));
