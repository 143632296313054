import React, {useEffect} from 'react';
import {observer, useLocalObservable} from "mobx-react-lite";
import {inject} from "mobx-react";
import TicketVariant from "./TicketVariant";
import PrimaryButton from "./PrimaryButton";
import {useSpring, animated} from "@react-spring/web";

function TicketVariants({DeviceStore, ticket, close}) {

  const {t, locale, defaultLocale, selectedVariants} = DeviceStore;

  const springs = useSpring({
    from: { y: 25, opacity: 0, },
    to: { y: 0, opacity: 1 },
  })

  const state = useLocalObservable(() => ({
    variants: {}
  }));

  useEffect(() => {
    state.variants = selectedVariants[ticket.id] ?? {};
    if(DeviceStore.iframe) {
      window.scrollTo(0, 0);
      window.parent.postMessage({
        type: 'scrollTop',
      }, "*");
    }
  }, []);

  const setAmount = (variant, amount) => {
    DeviceStore.updateVariantAmount(variant, amount);
  }

  let {theme} = DeviceStore;

  const confirm = () => {
    let total = 0;
    Object.values(state.variants).forEach(value => {
      total += value;
    })
    if(total > ticket.userlimit ?? 25){
      alert('Kies maximaal '+(ticket.userlimit ?? 25)+' tickets');
      return;
    }
    close();
  }

  return <div style={{zIndex: 9999}} className="fixed top-0 right-0 bottom-0 left-0 bg-gray-800 bg-opacity-50 z-50 overflow-scroll">
    <div className="mx-2">
      <animated.div style={springs} className="shadow-lg bg-white rounded-lg my-14 md:my-20 max-w-2xl mx-auto p-4 dark:bg-gray-800 dark:border dark:border-gray-700">
        <h2 className="font-bold text-lg dark:text-gray-100">{ticket.name[locale] ?? ticket.name[defaultLocale]}</h2>
        <hr className="my-4 dark:border-gray-700"/>
        <div className="space-y-2">
            {ticket.variants.map(variant => <TicketVariant amount={DeviceStore.selectedTickets.filter(t => t.variant_id === variant.id).length} limit={ticket.userlimit ?? 25} onChange={setAmount} variant={variant} key={variant.id}/>)}
        </div>
        <hr className="my-4 dark:border-gray-700"/>
        <div className="flex justify-end">
          <PrimaryButton theme={theme} loading={DeviceStore.loading} label={t('confirm')} onClick={confirm} />
        </div>
      </animated.div>
    </div>
  </div>
}

export default inject("DeviceStore")(observer(TicketVariants))
