import {Outlet} from "react-router-dom";
import EventHeader from "../components/eventHeader";
import Steps from "../components/steps";
import React from "react";

function EventLayout(){

  return (
        <div className="container">
          <EventHeader />
          <Steps/>
          <Outlet/>
        </div>
  )
}

export default EventLayout;
