import React from 'react';
import TicketRow from "./ticketRow";
import {useCollapse} from 'react-collapsed'
import {inject} from "mobx-react";

function Category({DeviceStore, category, tickets, chooseSeats, chooseVariants}){

  const {locale, defaultLocale} = DeviceStore;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: typeof window['cat_hidden_'+category.id] === 'undefined' ? !category.collapsed : !window['cat_hidden_'+category.id],
    onCollapseStart: () => {
      window['cat_hidden_'+category.id] = true;
  },
    onExpandEnd:() =>{
      window['cat_hidden_'+category.id] = false;
    }
  })

  const name = category.name[locale] ?? (category.name[defaultLocale] ?? category.name);
  const description = category.description[locale] ?? (category.description[defaultLocale] ?? null);

  return (
      <div>
        <div {...getToggleProps()} className="p-4 border-b bg-gray-50 flex justify-between items-center dark:bg-gray-800 dark:border-gray-700 dark:text-gray-200">
          <div>
            <h4 className="text-lg text-gray-900 font-bold dark:text-gray-200">{name}</h4>
            <p className="text-xs text-gray-600 dark:text-gray-400" style={{whiteSpace: 'pre-line'}}>{description}</p>
          </div>
          <div className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"w-6 h-6 transition-transform " + (isExpanded ? ' rotate-180' : '')}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                </svg>

          </div>
        </div>
       <div {...getCollapseProps()}>
          {tickets.map(ticket => <TicketRow key={ticket.id} chooseSeats={chooseSeats} chooseVariants={chooseVariants} ticket={ticket}/>)}
        </div>
      </div>
  )
}

export default inject('DeviceStore')(Category);
