import React, {useRef} from 'react';
import {XIcon} from "@heroicons/react/outline";

function DateInput({onChange, value, required, invalid, className, disabled, id, name}){

  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef,  setFocus ]
  }

  const daysInMonths = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31
  }

  const [dayRef, focusDay] = useFocus();
  const [monthRef, focusMonth] = useFocus();
  const [yearRef, focusYear] = useFocus();

  value = value ?? '';

  const parts = value.split('-');

  const day = parts[2] ?? '';
  const month = parts[1] ?? '';
  const year = parts[0] ?? '';

  const updateDate = (date) => {

    onChange({target: {value: date}})
  }

  const setDay = ({target}, changeFocus = true) => {
    let val = parseInt(target.value.replace(/\D/g,''));
    let valString = target.value.replace(/\D/g,'');
    if(isNaN(val)){
      val = '';
    }
    if(val > 31){
      val = 31;
      valString = '31';
    }
    let date = year + '-' + month + '-' + valString;
    updateDate(date)
    if((val > 3) || (valString.length > 1 && changeFocus)){
      focusMonth();
    }
  }

  const setMonth = ({target}) => {
    let val = parseInt(target.value.replace(/\D/g,''));
    let valString = target.value.replace(/\D/g,'');
    if(isNaN(val)){
      val = '';
    }
    if(val > 12){
      val = 12;
      valString = '12';
    }
    let date = year + '-' + valString + '-' + day;
    updateDate(date);
    if(val > 1 || valString.length > 1){
      checkDay(valString);
      focusYear();
    }

  }

  const setYear = ({target}) => {
    const maxYear = new Date().getFullYear();
    const minYear = maxYear - 100;
    let val = parseInt(target.value.replace(/\D/g,''));
    let valString = target.value.replace(/\D/g,'');
    if(isNaN(val)){
      val = '';
    }
    if(val > maxYear){
      val = maxYear;
    }
    if(valString.length === 4 && val < minYear){
      val = minYear;
    }
    let date = val + '-' + month + '-' + day;
    updateDate(date)
  }

  const checkMonthKey = ({key}) => {
    if(key === 'Backspace' && !month){
      focusDay();
    }
  }

  const checkYearKey = ({key}) => {
    if(key === 'Backspace' && !year){
      focusMonth();
    }
  }

  const reset = () => {
    updateDate('')
  }

  const focusInput = ({target}) => {
    if(target.tagName === 'DIV'){
      if(year){
        focusYear();
      } else if(month){
        focusYear();
      } else if(day) {
        focusMonth();
      } else {
        focusDay();
      }

    }
  }

  const checkYear = () => {
    const minYear = new Date().getFullYear() - 100;
    if(year && year < minYear){
      setYear({target: {value: minYear.toString()}})
    }
  }

  const checkDay = (m) => {
    if (!day || !m) return;
    if(parseInt(day) > daysInMonths[parseInt(m)]){
      let date = year + '-' + m + '-' + daysInMonths[parseInt(m)].toString();
      updateDate(date);
    }
  }

  return (
      <div className={'border border-gray-300 bg-white rounded-md p-2 shadow w-full sm:max-w-xs sm:text-sm max-w-lg flex justify-between items-center dark:bg-gray-950 dark:border-gray-700 ' + className + (invalid ? ' border-red-500' : ''  + (disabled ? ' opacity-50 cursor-not-allowed' : ''))} onClick={focusInput}>
        <div className="flex items-center">
          <input id={id} name={name+'_date_day'} disabled={disabled || false} className="border-0 p-0 h-5 focus:ring-0 max-w-2 bg-transparent dark:text-white" maxLength={2} size={day.length ? day.length : 2} type="tel" value={day} placeholder="--" onChange={setDay} ref={dayRef} required={required ?? false}/>
          <span className="mr-1 dark:text-gray-200">/</span>
          <input name={name+'_date_month'} disabled={disabled || false} className="border-0 p-0 h-5 focus:ring-0 max-w-2 bg-transparent dark:text-white" maxLength={2} onKeyDown={checkMonthKey} onBlur={checkDay} size={month.length ? month.length : 2} type="tel" value={month} placeholder="--" onChange={setMonth} ref={monthRef} required={required ?? false}/>
          <span className="mr-1 dark:text-gray-200">/</span>
          <input name={name+'_date_year'} disabled={disabled || false} className="border-0 p-0 h-5 focus:ring-0 max-w-4 bg-transparent dark:text-white" minLength={4} maxLength={4} onBlur={checkYear} onKeyDown={checkYearKey} size={year.length ? year.length : 3} type="tel" value={year} placeholder="----" onChange={setYear} ref={yearRef} required={required ?? false}/>
        </div>
        {(day || month || year) && !disabled ? <XIcon className="h-5 dark:text-gray-200" onClick={reset}/> : null }
      </div>
  )
}

export default DateInput;
