import {Link} from "react-router-dom";
import PrimaryButton from "./PrimaryButton";

function WaitingListBanner({theme}){
  return (<div className="bg-white border dark:border-gray-700 dark:bg-gray-900 dark:text-white mb-4 shadow rounded-md p-4 flex  justify-between items-center">
    <p className="mr-4">
      Geen tickets meer beschikbaar? Schrijf je dan in voor de wachtlijst.
    </p>
    <Link className="btn" to={'waiting-list'}>
      <PrimaryButton theme={theme} label="Inschrijven"/>
    </Link>
  </div>)
}

export default WaitingListBanner;
