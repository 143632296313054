import React from 'react';

export default function Logo({color, width}){
  return (
      <a href="https://www.mijnetickets.nl" rel="noreferrer" target="_blank" className="flex justify-center mijnetickets-logo">
        <svg
            style={{color: color}}
            className="text-blue-600"
            width={width || 120}
            viewBox="0 0 284 43"
            xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="currentColor" fillRule="evenodd">
            <path d="M279.3 13.9v20.2h4.2V13zM270.7 18.5c-1.2 0-2.7.2-4.2.5-1.5.4-2.7.7-3.4 1.1V34h4.2V22.6h.1c.3-.1.6-.3 1.1-.4.5-.1.9-.2 1.4-.2 2 0 3.1.9 3.1 2.8v9.3h4.2V23.9c0-1.9-.5-3.3-1.6-4.1-1.2-.9-2.8-1.3-4.9-1.3ZM256.2 30h4.2v4.2h-4.2zM252.9 23.7c-1-1-2.2-1.6-3.8-1.9-1.5-.4-2.6-.7-3.3-1-.5-.2-.9-.6-1-1v-.2c0-.2 0-.4.1-.7.1-.3.3-.5.5-.7.5-.3 1.1-.5 2.1-.5 1.7 0 3.4.4 5.1 1.3.5-1.4 1.3-3.4 1.6-4.5-1.1-.5-2.2-.8-3.1-1.1-.9-.3-2.1-.4-3.3-.4-2.6 0-4.7.5-6.3 1.6-1.6 1-2.5 2.9-2.5 5.2 0 1.5.3 2.6.9 3.4.6.8 1.3 1.4 2 1.7.8.3 1.8.7 3.2 1 1.2.3 1.9.5 2.5.8.6.3.9.9.9 1.7 0 .6-.3 1-.8 1.3-.5.3-1.3.5-2.3.5-2 0-5.4-.6-5.9-.8V34c1.1.3 4 .7 7.2.7 1.3 0 2.5-.3 3.7-.8 1.2-.5 2.2-1.2 3-2.2.8-1 1.2-2.3 1.2-3.9-.3-1.7-.7-3.1-1.7-4.1ZM232.3 8.6l-5.9 1.3v4h-3.8l2 4.7h1.8v9.9c0 2.1.6 3.4 1.7 4.4 1.1 1.1 2.9 1.6 5.1 1.6 1.1 0 2.1-.2 2.9-.5v-4.6c-.1 0-.2.1-.3.2-.5.2-.9.2-1.4.2-.8 0-1.3-.2-1.6-.6-.3-.4-.5-.9-.5-1.7v-9h4.5v-4.7h-4.5V8.6Z" />
            <path
                d="M213.4 13c-3.2 0-5.7 1-7.4 2.9-1.7 1.9-2.6 4.7-2.6 8.2 0 2.1.4 4 1.3 5.6.8 1.6 2 2.8 3.6 3.6.6.3 1.2.6 1.8.8 1.8.6 3.8.5 3.8.5 3 0 7-1.3 7.7-1.6-.3-.8-1.5-4.3-1.5-4.3-.8.3-1.5.6-2.1.8-1 .3-2 .4-3 .4-3.4 0-5.3-1.5-5.9-4.4v-.2h13.5c0-4.6-1-8-3-10-1.5-1.5-3.6-2.3-6.2-2.3Zm-4.3 8.5c.4-2.7 1.8-4.1 4-4.1 1.2 0 2.1.3 2.7 1 .6.6 1 1.7 1.2 3.1h-7.9Z"
                fillRule="nonzero"
            />
            <path d="M203.8 13.9H197l-6.2 7.8.1-15.9-5.7 1.3v27h5.6v-8.9l6.7 8.9h7.1l-8.8-11.6zM172.5 28.3c-1-1-1.4-2.7-1.4-4.8 0-1.9.5-3.4 1.6-4.3 1-.9 2.5-1.4 4.3-1.4.9 0 1.8.1 2.6.4.8.2 1.7.6 2.7 1v-4.9c-.8-.4-1.8-.7-2.9-.9-1.1-.2-2.3-.3-3.4-.3-3.2 0-5.7.9-7.6 2.8-1.9 1.9-2.8 4.7-2.8 8.3 0 2.3.5 4.2 1.4 5.8.9 1.6 2.2 2.8 3.8 3.5 1.6.8 3.4 1.2 5.3 1.2 1.3 0 2.4-.1 3.4-.4 1-.2 1.9-.6 2.9-1.1v-4.8c-1.8 1-3.6 1.5-5.1 1.5-2.3 0-3.8-.5-4.8-1.6ZM157.1 5.8h5.7v5.6h-5.7zM157.3 13.9v20.2h5.6V13.9zM150 8.6l-5.9 1.3v4h-3.8l2 4.7h1.8v9.9c0 2.1.6 3.4 1.7 4.4 1.1 1.1 2.9 1.6 5.1 1.6 1.4 0 2.6-.3 3.6-.8v-4.6c-.3.2-.6.3-1 .5-.5.2-.9.2-1.4.2-.8 0-1.3-.2-1.6-.6-.3-.4-.5-.9-.5-1.7v-9h4.5v-4.7H150V8.6Z" />
            <path
                d="M131 13c-3.2 0-5.7 1-7.4 2.9-1.7 1.9-2.6 4.7-2.6 8.2 0 2.1.4 4 1.3 5.6.8 1.6 2 2.8 3.6 3.6.6.3 1.2.6 1.8.8 1.8.6 3.8.5 3.8.5 3 0 7-1.3 7.7-1.6-.3-.8-1.5-4.3-1.5-4.3-.8.3-1.5.6-2.1.8-1 .3-2 .4-3 .4-3.4 0-5.3-1.5-5.9-4.4v-.2h13.5c0-4.6-1-8-3-10-1.4-1.5-3.5-2.3-6.2-2.3Zm-4.2 8.5c.4-2.7 1.8-4.1 4-4.1 1.2 0 2.1.3 2.7 1 .6.6 1 1.7 1.2 3.1h-7.9Z"
                fillRule="nonzero"
            />
            <path d="M110.1 13.1c-1.7 0-3.6.2-5.7.7-2.1.5-3.6 1-4.7 1.5v18.8h5.6V18.6l.1-.1c.4-.2.9-.4 1.5-.5.6-.1 1.3-.2 1.9-.2 2.7 0 4.1 1.3 4.1 3.8v12.6h5.6V20.3c0-2.6-.7-4.4-2.1-5.5-1.4-1.2-3.5-1.7-6.3-1.7ZM90.6 5.8h5.7v5.6h-5.7zM90.6 13.9v20.6c0 2-.9 3-2.8 3h-.4v4.6c2.7 0 4.9-.6 6.5-1.8 1.6-1.2 2.4-3.1 2.4-5.8V13.9h-5.7ZM81.2 5.8h5.6v5.6h-5.6zM81.2 13.9h5.6v20.2h-5.6zM69.5 13.1c-1 0-2.1.1-3.3.4-1.2.3-2.3.6-3.3 1.1h-.1l-.1-.1c-1.2-1-3.2-1.4-5.9-1.4-1.7 0-3.6.2-5.7.7-2.1.5-3.6 1-4.7 1.5v18.8H52V18.6l.1-.1c.9-.5 2-.7 3.1-.7 2.7 0 4.1 1.3 4.1 3.8v12.6h5.6V20.3c0-.4 0-.9-.1-1.6v-.1l.1-.1c.8-.5 1.8-.8 2.9-.8 2.7 0 4.1 1.3 4.1 3.8v12.6h5.6V20.3c0-2.6-.7-4.5-2-5.6-1.1-1.1-3.2-1.6-6-1.6ZM5.6 21.3h3.1v3.1H5.6zM.5 23.2H3v2.5H.5zM6.3 26.4h2.4v2.4H6.3zM1.8 11.3H7v5.2H1.8zM7.6 0h2v2h-2zM0 4h3.6v3.6H0z" />
            <path
                d="M37.1 18.7V5.8h-9.7c-.2 1.6-1.5 2.8-3.1 2.8s-2.9-1.2-3.1-2.8h-6.9v2.8h-2.8v16.8h2.2v2.2h2.5v4.7h-4.7v9.8h9.7c.2-1.6 1.5-2.8 3.1-2.8s2.9 1.2 3.1 2.8h9.7V22.9h-4.3v-4.3h4.3v.1Zm-12.8 6.5h-3.9v-3.9H15V16h5.4v5.3h3.9v3.9Zm7.8 8.9h-3.7v-3.6h3.7v3.6Z"
                fillRule="nonzero"
            />
          </g>
        </svg>
      </a>
  )
}
