import React from 'react';
import {observer} from "mobx-react-lite";

function TicketVariant({variant, amount, onChange, limit}) {

  const decrease = () => {
    if(amount < 1){
      return;
    }
    onChange(variant, amount - 1)
  }

  const increase = () => {
    if(amount >= limit){
      return;
    }
    onChange(variant, amount + 1)
  }

  return <div className="flex justify-between">
    <div className="font-bold text-gray-800 text-sm md:text-md dark:text-gray-200">{variant.name}</div>
    <div className="">
      <div className="flex border border-gray-300 rounded items-center w-32 dark:bg-gray-950 dark:text-white dark:border-gray-700">
        <button onClick={decrease} className="decrease-ticket-btn p-2 border-r w-10 dark:border-gray-700"><span className={!amount ? "opacity-20" : ""}>-</span></button>
        <input name={'viarant_select_' + variant.id} className="ticket-amount-label border-none w-12 text-center outline-none bg-transparent" readOnly={true} value={amount} type="text"/>
        <button onClick={increase} className="increase-ticket-btn p-2 border-l w-10 dark:border-gray-700"><span className={amount >= limit ? "opacity-20" : ""}>+</span></button>
      </div>
    </div>
  </div>
}

export default observer(TicketVariant);
