import Logo from "./logo";
import React from "react";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";

function Footer({DeviceStore}){

  const {locale, t, theme, showFooter} = DeviceStore;

  const changeLocale = (ev) => {
    DeviceStore.setLocale(ev?.target?.value);
  }

  if(!showFooter){
    return null;
  }

  let localeIconName = locale;
  if(localeIconName === 'en'){
    localeIconName = 'gb';
  }

  return (
      <div className="container">
        <div className="sub-footer flex items-center px-4 justify-between mt-2">
          <div className="flex items-center space-x-1 relative">
            <div style={{color: theme.primary_color}} className="locale-btn uppercase -ml-1 text-blue-600 select-none bg-opacity-75 text-sm cursor-pointer font-bold rounded-bl">
              <span className={'text-xl fi fi-'+localeIconName}></span>
            </div>
            <select name="changeLocale" value={locale} onChange={changeLocale} style={{width: '24px', left: '-10px', top: 0, bottom: 0}} className="appearance-none p-0 m-0 absolute opacity-0 cursor-pointer">
              <option value="nl">Nederlands</option>
              <option value="en">English</option>
              <option value="de">Deutsch</option>
              <option value="fr">Français</option>
              <option value="es">Español</option>
            </select>
            <span className="opacity-25 dark:text-white w-1"></span>
            <a className="text-blue-600 text-sm" target="_blank" rel="noreferrer" style={{color: theme.primary_color}} href={'https://mijnetickets.nl/customer?lang='+locale}>
              {t('lost_tickets')}
            </a>

          </div>
          <Logo color={theme.primary_color}/>
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(Footer));
