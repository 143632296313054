import {useEffect} from 'react';

export default function Index(){

  useEffect(() => {

    const {hostname} = window.location;

    if(process.env.NODE_ENV === 'production'){

      if(hostname.includes('mijnetickets')) {
        window.location.href = 'https://www.mijnetickets.nl';
      } else {
        window.location.href = 'https://' + hostname.replace('tickets.', '')
      }

    }

  }, [])

  return null;
}
