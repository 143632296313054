import {useCollapse} from "react-collapsed";
import TicketField from "../form/TicketField";
import React, {useEffect} from "react";
import {inject} from "mobx-react";
import {useLocalObservable, observer} from "mobx-react-lite";

function TicketForm({DeviceStore, fields, ticket, setField, onSubmit, loading, editable, expanded = false}){

  const {locale, t, theme} = DeviceStore;

  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } = useCollapse();

  const state = useLocalObservable(() => ({
    data: {},
  }));


  useEffect(() => {
    Object.values(fields).forEach(field => {
      state.data[field.name] = ticket[field.name]
    })
    if(expanded){
      setExpanded(true)
    }
  },[])

  const submit = async (e) => {
    const result = await onSubmit(e, state.data);
    if(result) {
      setExpanded(false)
    }
  }

  return (
      <form onSubmit={submit}>
        <h3 {...getToggleProps()} className={'text-md font-bold flex justify-between items-center bg-blue-600 p-3 rounded-md ' + (theme.primary_light ? 'text-black' : 'text-white')}  style={{backgroundColor: theme.primary_color}}>
          {t('personalize')}
          <span>{isExpanded ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

          }</span>
        </h3>

       <div {...getCollapseProps()}>
         <div className="grid sm:grid-cols-2 gap-2">
           {
             Object.entries(fields).map((entry, ii) => {
               const field = entry[1];
               const setValue = ({target: {value}}) => state.data[field.name] = value
               return (
                   <div key={ii}>
                     <label className="text-gray-800 dark:text-gray-200 text-xs font-bold">
                       {field.label[locale]}{field.required ? <span className="required text-red-500">*</span> : ''}
                     </label>
                     <TicketField disabled={!editable} value={state.data[field.name]} onChange={setValue} field={field}/>
                   </div>
               )
             })
           }
         </div>

         {editable ? <button type="submit" style={{backgroundColor: theme.primary_color}} className={'bg-blue-600 font-bold text-sm mb-2 rounded-md p-4 py-2 mt-3 ' + (theme.primary_light ? 'text-black' : 'text-white') + (loading ? ' opacity-50 cursor-not-allowed' : '')}>
           {loading ? t('saving') : t('save')}
         </button> : <div className="h-2"></div> }

       </div>
      </form>
  )
}

export default inject("DeviceStore")(observer(TicketForm))
