import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite"
import {inject} from "mobx-react";
import Loader from "../components/loader";
import NotFound from "../components/notFound";
import OrganisationHeader from "../components/organisationHeader";
import {CalendarIcon} from "@heroicons/react/outline";
import ErrorView from "../components/errorView";

function Organisation ({DeviceStore}) {

  const {organisation_slug} = useParams();
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    notFound: false,
    loading: false,
    error: false,
    eventLoading: null,
    organisation: null,
    events: [],
    contact: false,
  }));

  const {t, locale} = DeviceStore;

  useEffect(() => {
    DeviceStore.setStep(null);
    if(!DeviceStore.organisationData) {
      state.loading = true;
    }
    getEvents();
  }, []);

  const getEvents = async () => {
    if(!DeviceStore.organisationData) {
      state.loading = true;
    }
    try {
      await DeviceStore.getEvents(organisation_slug);
    } catch (e){
      if(e.response?.status === 404){
        state.notFound = true;
      } else {
        state.error = true;
        DeviceStore.setError('Er ging iets fout bij het ophalen van de informatie. Probeer het later nog eens')
      }
    }
    state.loading = false;
  }

  if(state.notFound){
    return (<NotFound title="We konden de webshop niet vinden."/>)
  }


  if(state.error){
    return <ErrorView/>
  }

  if(state.loading || !DeviceStore.organisationData){
    return (
        <Loader/>
    )
  }

  const {theme} = DeviceStore;

  const getEvent = async (shop_id) => {

    state.eventLoading = shop_id;

    DeviceStore.setClientId(shop_id);

    await DeviceStore.fetchQueue(shop_id, 'error');

    const {queueData} = DeviceStore;

    if (queueData.status === 'pre-queue') {
      navigate('/'+organisation_slug + '/'+shop_id + '/pre-queue');
      return true;
    }

    if (queueData.status === 'queue') {
      navigate('/'+organisation_slug + '/'+shop_id + '/queue');
      return true;
    }

    if(queueData.status === 'error'){
      state.eventLoading = null;
      return;
    }

    const status = await DeviceStore.getEvent(organisation_slug, shop_id, !!DeviceStore.eventData);
    if(status) {
      navigate('/' + organisation_slug + '/' + shop_id);
      if(DeviceStore.iframe) {
        window.scrollTo(0, 0);
        window.parent.postMessage({
          type: 'scrollTop',
        }, "*");
      }
    } else {
      state.eventLoading = null;
    }
  }

  const renderEvent = (event) => {

    let date = event.type === 'recurring' ? t('multiple_dates') : (event.date[locale] ?? (event.date.en ?? t('unknown_date')));

    return (
        <div className="events-container-item">
          {event.image ? <img src={event.image} alt={event.name} className="events-container-item-image hidden"/> : null }
          <div className="p-4 px-5 flex justify-between items-center">
            <div>
              <h2 className="font-semibold text-blue-600 dark:text-blue-400" style={{color: theme.primary_color}}>{event.name}</h2>
              <p className="text-sm text-gray-500 dark:text-gray-200 flex items-center">
                <CalendarIcon className="h-4 w-4 mr-1.5 text-gray-400 dark:text-gray-300"/>
                {date}
              </p>
            </div>
            {event.shop_id !== state.eventLoading ? <button>
                  <svg className="h-5 w-5 text-gray-400 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/>
                  </svg>
                </button> :
                <svg className="dark:text-white" style={{color: theme.primary_color}} fill="currentColor" xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
                  <style>{"@keyframes spinner_AtaB{to{transform:rotate(360deg)}}"}</style>
                  <path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm0 19a8 8 0 1 1 8-8 8 8 0 0 1-8 8Z" opacity={0.25}/>
                  <path d="M10.14 1.16a11 11 0 0 0-9 8.92A1.59 1.59 0 0 0 2.46 12a1.52 1.52 0 0 0 1.65-1.3 8 8 0 0 1 6.66-6.61A1.42 1.42 0 0 0 12 2.69a1.57 1.57 0 0 0-1.86-1.53Z" style={{
                    transformOrigin: "center",
                    animation: "spinner_AtaB .75s infinite linear",
                  }}
                  />
                </svg>
            }
          </div>
        </div>
    )
  }

  const {events} = DeviceStore.organisationData;

  return (
      <div className="container events-container lg:px-4">
        <OrganisationHeader/>
        <div className="mt-4 lg:px-0">
          {events.length === 0 ? <div className="p-4 bg-white rounded-md border shadow-md text-center">
            {t('no_upcoming_events_found')}
          </div> : null}
          <div className="events-container-content space-y-4">
            {events.map(event => {
              return (
                  <div key={event.shop_id} onClick={() => getEvent(event.shop_id)} className="bg-white rounded-md overflow-hidden shadow-md border cursor-pointer dark:bg-gray-900 dark:border-gray-700 block">
                    {renderEvent(event)}
                  </div>
              )
            })}
          </div>
        </div>
      </div>

  )
}

export default inject("DeviceStore")(observer(Organisation));
