export function preloadImage(url){
  return new Promise((resolve) => {
    setTimeout(resolve, 1000)
    let img = new Image()
    img.onload = () => resolve()
    img.onerror = resolve()
    img.src = url
  })
}

export function pad(num, size = 2) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export async function sleep(time){
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}


export function getQueryParams(key = null) {
  let query =  window.location.search.replace('?', '').split('&').map(q => {
    let parts = q.split('=');
    return {
      key: parts[0],
      value: parts[1],
    };
  }).filter(q => q.key);

  if(key){
    let param = query.find(q => q.key === key);
    if(param){
      return param.value;
    }
    return null;
  }

  return query;
}
