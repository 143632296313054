import {CheckIcon} from '@heroicons/react/solid'
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";
import {Link, useNavigate, useLocation} from "react-router-dom";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Steps({DeviceStore}) {

  const navigate = useNavigate();

  const location = useLocation();

  const path = location.pathname.replace(/^\/|\/$/g, '').split('/');

  if(!DeviceStore.eventData){
    return null;
  }

  const organisation_slug = path.length ? path[0] : null;

  const {theme, entranceShop} = DeviceStore;

  let {t, step, organisationData} = DeviceStore;

  if(!step){
    return null;
  }

  let steps = [
    { id: '01', name: 'Tickets', description: t('step1_desc'), href: '#', status: step === 1 ? 'current' : 'complete' },
    { id: '02', name: t('step2_title'), description: t('step2_desc'), href: '#', status: step === 2 ? 'current' : (step < 2 ? 'upcoming' : 'complete') },
    { id: '03', name: t('step3_title'), description: t('step3_desc'), href: '#', status: step === 3 ? 'current' : (step < 3 ? 'upcoming' : 'complete') },
  ]

  if(entranceShop){
    steps = [
      { id: '01', name: 'Tickets', description: t('step1_desc'), href: '#', status: step === 1 ? 'current' : 'complete' },
      { id: '02', name: t('step3_title'), description: t('step3_desc'), href: '#', status: step === 3 ? 'current' : (step < 3 ? 'upcoming' : 'complete') },
    ]
  }

  const onClick = (step) => {
    if(step === 1){
      navigate(`/${DeviceStore.eventData.organisation_slug}/${DeviceStore.eventData.shop_id}`)
    }
    if(step === 2){
      navigate(`/${DeviceStore.eventData.organisation_slug}/${DeviceStore.eventData.shop_id}/registration`)
    }
  }

  return (
      <div className="steps bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 mb-4 dark:bg-gray-900 dark:border-gray-700">
        <nav className="sm:hidden">
          <ol className="flex items-center p-3 px-4">

            {organisationData ?
                <li className="flex justify-center items-center w-16 bg-blue-600 -ml-4 -my-4 h-16 mr-4"  style={{backgroundColor: theme.primary_color}}>
                  <Link to={'/'+organisation_slug}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"h-6 " + (theme.primary_light ? 'text-black' : 'text-white')}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                  </Link>
                </li>
                : null}

            {steps.map((step, stepIdx) => (
                <li key={step.name} style={{width: stepIdx !== steps.length - 1 ? '50%' : 'auto'}} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative step-' + step.status )}>
                  {step.status === 'complete' ? (
                      <>
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="h-0.5 w-full bg-blue-600"  style={{backgroundColor: theme.primary_color}} />
                        </div>
                        <button
                            type="button"
                            onClick={() => onClick(stepIdx + 1)}
                            className="relative w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full"
                            style={{backgroundColor: theme.primary_color}}
                        >
                          <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                          <span className="sr-only">{step.name}</span>
                        </button>
                      </>
                  ) : step.status === 'current' ? (
                      <>
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700" />
                        </div>
                        <div
                            className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full dark:bg-gray-900"
                            style={{borderColor: theme.primary_color}}
                            aria-current="step"
                        >
                          <span className="h-2.5 w-2.5 bg-blue-600 rounded-full"  style={{backgroundColor: theme.primary_color}} aria-hidden="true" />
                          <span className="sr-only">{step.name}</span>
                        </div>
                      </>
                  ) : (
                      <>
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="h-0.5 w-full bg-gray-200 dark:bg-gray-700" />
                        </div>
                        <div
                            className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full dark:border-gray-700 dark:bg-gray-900"
                        >
                  <span
                      className="h-2.5 w-2.5 bg-transparent rounded-full"
                      aria-hidden="true"
                  />
                          <span className="sr-only step-label">{step.name}</span>
                        </div>
                      </>
                  )}
                </li>
            ))}
          </ol>
        </nav>


      <div className="steps-container hidden sm:block">
        <nav className="mx-auto">
          <ol className="overflow-hidden sm:flex">

            {organisationData ?
            <li className="flex justify-center items-center w-20 bg-blue-600 relative bck-to-org-btn"  style={{backgroundColor: theme.primary_color}}>
                  <Link to={'/'+organisation_slug}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"h-8 calendar-icon " + (theme.primary_light ? 'text-black' : 'text-white')}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                    <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center back-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={"w-8 " + (theme.primary_light ? 'text-black' : 'text-white')}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                      </svg>
                    </div>

                  </Link>
            </li>
            : null}

            {steps.map((step, stepIdx) => (
                <li key={step.id} className="step relative overflow-hidden sm:flex-1">
                  <div
                      className={classNames(
                          stepIdx === 0 ? 'border-b-0 rounded-t-md' : '',
                          stepIdx === steps.length - 1 ? 'border-t-0 rounded-b-md' : '',
                          'border border-gray-200 overflow-hidden sm:border-0 step-' + step.status
                      )}
                  >
                    {step.status === 'complete' ? (
                        <div onClick={() => onClick(stepIdx + 1)} className="group cursor-pointer">
                    <span
                        className="absolute top-0 left-0 w-1 h-full bg-transparent sm:w-full sm:h-1 sm:bottom-0 sm:top-auto"
                        aria-hidden="true"
                    />
                          <span
                              className={classNames(
                                  stepIdx !== 0 ? 'sm:pl-7' : '',
                                  'px-6 py-4 flex items-start text-sm font-medium flex items-center'
                              )}
                          >
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full" style={{backgroundColor: theme.primary_color}}>
                          <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold tracking-wide uppercase step-label dark:text-gray-200">{step.name}</span>
                        {/*<span className="text-sm font-medium text-gray-500">{step.description}</span>*/}
                      </span>
                    </span>
                        </div>
                    ) : step.status === 'current' ? (
                        <div>
                    <span
                        className="absolute top-0 left-0 w-1 h-full bg-blue-600 sm:w-full sm:h-1 sm:bottom-0 sm:top-auto"
                        aria-hidden="true"
                        style={{backgroundColor: theme.primary_color}}
                    />
                          <span
                              className={classNames(
                                  stepIdx !== 0 ? 'sm:pl-7' : '',
                                  'px-6 py-4 flex items-start text-sm font-medium flex items-center'
                              )}
                          >
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full" style={{borderColor: theme.primary_color}}>
                          <span className="text-blue-600"  style={{color: theme.primary_color}}>{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold text-blue-600 tracking-wide uppercase"  style={{color: theme.primary_color}}>
                          {step.name}
                        </span>
                        {/*<span className="text-sm font-medium text-gray-500">{step.description}</span>*/}
                      </span>
                    </span>
                        </div>
                    ) : (
                        <div className="group">
                    <span
                        className="absolute top-0 left-0 w-1 h-full bg-transparent sm:w-full sm:h-1 sm:bottom-0 sm:top-auto dark:border-gray-700"
                        aria-hidden="true"
                    />
                          <span
                              className={classNames(
                                  stepIdx !== 0 ? 'sm:pl-7' : '',
                                  'px-6 py-4 flex items-start text-sm font-medium flex items-center'
                              )}
                          >
                      <span className="flex-shrink-0">
                        <span className="w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full dark:border-gray-500">
                          <span className="text-gray-500 dark:text-gray-400">{step.id}</span>
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
                        <span className="text-xs font-semibold text-gray-500 tracking-wide uppercase dark:text-gray-300">{step.name}</span>
                        {/*<span className="text-sm font-medium text-gray-500">{step.description}</span>*/}
                      </span>
                    </span>
                        </div>
                    )}

                    {stepIdx !== 0 ? (
                        <>
                          {/* Separator */}
                          <div className="hidden absolute top-0 left-0 w-3 inset-0 sm:block" aria-hidden="true">
                            <svg
                                className="h-full w-full text-gray-300 dark:text-gray-700"
                                viewBox="0 0 12 82"
                                fill="none"
                                preserveAspectRatio="none"
                            >
                              <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                            </svg>
                          </div>
                        </>
                    ) : null}
                  </div>
                </li>
            ))}
          </ol>
        </nav>
      </div>
        </div>
  )
}

export default inject("DeviceStore")(observer(Steps))
