import {CalendarIcon, LocationMarkerIcon} from "@heroicons/react/solid";
import QRCode from "react-qr-code";
import TicketForm from "../components/order/TicketForm";
import ModalButton from "../components/ModalButton";
import React, {useEffect} from "react";
import {inject} from "mobx-react";
import {useParams} from "react-router-dom";
import {useLocalObservable, observer} from "mobx-react-lite";
import axios from "axios";
import NotFound from "../components/notFound";
import Loader from "../components/loader";

function Ticket({DeviceStore}){

  const {apiUrl} = DeviceStore;

  const {hash} = useParams();

  const {t, setLocale} = DeviceStore;

  const state = useLocalObservable(() => ({
    notFound: false,
    loading: false,
    loadingTicket: null,
    data: null,
    error: false,
    setField(key, value){
      state.data.ticket[key] = value;
    },
  }));

  useEffect(() => {
    getTicket();
  },[]);

  /**
   * Get the ticket from the API
   *
   * @returns {Promise<void>}
   */
  const getTicket = async () => {
    state.loading = true;
    try {
      const {data} = await axios.get(apiUrl + '/shop/ticket/' + hash);
      state.data = data;

      if(data.lang) {
        setLocale(data.lang);
      }

      document.title = t('ticket') +' ' + data.ticket.barcode + ' - ' + state.data.event.name;

      if(data.theme){
        DeviceStore.setTheme(data.theme)
      }

    } catch (e){
      if(e.response?.status === 404){
        state.notFound = true;
      } else {
        state.error = true;
      }
    }
    state.loading = false
  }

  if(state.error){
    return (
        <NotFound title="Er ging iets fout bij het ophalen van je bestelling. Probeer het later nog eens."/>
    )
  }

  if(state.notFound){
    return (<NotFound title="We konden de ticket niet vinden."/>)
  }

  if(state.loading || !state.data){
    return (<Loader/>)
  }

  const {ticket, event, status} = state.data;

  let {theme} = DeviceStore;


  const personal = state.data.ticket_fields && !ticket.voucher && !ticket.disable_personalization;


  /**
   * Check if a ticket can be downloaded
   *
   * @param ticket
   * @returns {boolean}
   */
  const canDownloadTicket = (ticket) => {
    let canDownload = true;

    if(personal) {
      if(!ticket.voucher && !ticket.disable_personalization) {
        Object.values(state.data.ticket_fields).forEach(f => {
          if (!ticket[f.name] && f.required) {
            canDownload = false;
          }
        })
      }
    }
    return canDownload;
  }

  const canDownload = canDownloadTicket(ticket)


  /**
   * Update the ticket name
   *
   * @param e
   * @param data
   * @returns {Promise<boolean>}
   */
  const updateNames = async (e, data) => {
    e.preventDefault();
    if(state.loadingTicket){
      return;
    }

    Object.entries(data).forEach(entry => {
      state.setField(entry[0], entry[1])
    })

    state.loadingTicket = true;
    try {
      await axios.post(apiUrl + '/shop/ticket/'+hash+'/update', ticket);
    } catch (e){
      DeviceStore.setError(e.response.data.message)
      state.loadingTicket = null;
      return false;
    }
    state.loadingTicket = null;
    return true;
  }

  function renderCancelled(){
    return (
        <div className="p-4 dark:text-white">
          <p className="order-content-text text-sm">Deze ticket is niet meer beschikbaar.</p>
        </div>
    )
  }


  const renderPaid = () => {
    return (
        <div className="p-4">

          <div className="sm:flex sm:justify-between mb-6 sm:mb-4">
            <div className="text-center sm:text-left dark:text-white">
              <h2 className="text-xl font-bold">{ticket.name}</h2>

              { personal ? <p>{ticket.first_name} {ticket.last_name}</p> : null }
              <p>{ticket.barcode}</p>
            </div>
            <div className="flex justify-center">
              <div className="mt-4 sm:mt-0 w-60 sm:w-52">
                <div className="border-2 p-4 bg-white rounded-md">
                  <QRCode
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      size={256}
                      className={canDownload ? '' : 'blur opacity-75'}
                      value={ticket.barcode}
                  />
                </div>
              </div>
            </div>
          </div>

          {canDownload ? <div className="grid gap-2 sm:grid-cols-1">
            <ModalButton
                theme={theme}
                label={t('download_ticket') + ' (PDF)'}
                href={ticket.pdf}
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                </svg>}
            />
            <ModalButton
                theme={theme}
                label={t('add_to_wallet')}
                href={ticket.pkpass}
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                </svg>}
            />
          </div> : <div className="p-4 bg-red-500 text-white rounded-md">{t('ticket_available_after_personalization')}</div> }

          {personal ? <div className="mt-2">
            <TicketForm
                editable={ticket.can_edit}
                fields={state.data.ticket_fields}
                expanded={!ticket.first_name}
                loading={state.loadingTicket === ticket.barcode}
                setField={state.setField}
                onSubmit={(e, data) => updateNames(e, data, ticket.barcode)}
                ticket={ticket}
            />
          </div> : null }

        </div>
    )
  }

  return (
      <div className="container">

        <div className="event-header overflow-hidden shadow-md my-4 rounded-md">
          {theme.image ?
              <div className="shop-header bg-gray-800 text-white relative">
                <img src={theme.image} alt="" className="block w-full object-cover"/>
              </div> : null }
          <div className="block p-4 py-3 bg-blue-900 relative " style={{backgroundColor: theme.primary_color}}>
            <h1 className={'event-title text-xl font-semibold mb-1 md:mb-0 ' + (theme.primary_light ? 'text-black' : 'text-white')}>{event.name}</h1>
            <div className={'md:flex items-center space-y-1 md:space-y-0 md:space-x-4 text-sm ' + (theme.primary_light ? 'text-black' : 'text-white')}>
              <h2 className="event-date flex items-center">
                <CalendarIcon className="h-4 mr-2"/>
                {event.date ?? 'Datum nog niet bekend'}
              </h2>
              {event.location ?
                  <h3 className="event-location flex items-center">
                    <LocationMarkerIcon className="h-4 mr-2"/>
                    {[event.location.address ?? event.location.name, event.location.city].filter(v => v).join(', ')}
                  </h3> : null }
            </div>
            <button type="button" className="text-white absolute right-2 bottom-2 opacity-75">

            </button>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-900 rounded-md border dark:border-gray-700 mt-4 shadow">
          {status === 'cancelled' || status === 'refunded' ? renderCancelled() : null}
          {status === 'paid' ? renderPaid() : null}
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(Ticket));
