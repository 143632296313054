import {inject, observer} from "mobx-react";
import {useEffect} from "react";

function ErrorView({DeviceStore}){

  const {t} = DeviceStore;

  useEffect(() => {
    DeviceStore.setForceFooter();
  }, []);

  const {eventData, theme} = DeviceStore;

  return (
      <div className={'container ' + (eventData ? '' : 'pt-4')}>
        <div className="bg-white rounded-lg overflow-hidden shadow-md border border-gray-200 dark:bg-gray-900 dark:border-gray-700">
          <div className="p-8 flex justify-center items-center">
            <div className="flex flex-col justify-center w-64">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-32 h-32 mx-auto text-gray-500 dark:text-gray-300 inline-block">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
              </svg>
              <p className="text-gray-500 dark:text-gray-300 text-center font-bold">{t('something_went_wrong2')}</p>
              <p className="text-gray-500 dark:text-gray-300 text-center text-sm">{t('try_again_later')}</p>
              <a className="bg-blue-600 py-2 mt-4 p-4 rounded-md text-white inline-block text-center font-semibold" style={{backgroundColor: theme.primary_color}} href={window.location.href}>
                {t('try_again')}
              </a>
            </div>
          </div>
        </div>
      </div> )

}

export default inject("DeviceStore")(observer(ErrorView))
