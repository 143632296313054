import React from 'react';
import {InformationCircleIcon} from "@heroicons/react/outline";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";

function organisationHeader({DeviceStore}){

  if(DeviceStore.iframe){
    return null;
  }

  const {theme} = DeviceStore;

  return (
      <div className="rounded-lg overflow-hidden shadow-md mt-4">
        {!theme.image ? null : <div className="shop-header bg-gray-800 text-white relative">
          <img src={theme.image} alt="" className="header-image block w-full object-cover"/>
        </div>}
        <div className="block p-4 py-3 bg-blue-600 relative" style={{backgroundColor: theme.primary_color}}>
          <h1 className={"event-title text-xl font-semibold md:mb-0 " + (theme.primary_light ? 'text-black' : 'text-white')} >
            {DeviceStore.organisation_info.name}
          </h1>
          <button type="button" className={"absolute right-2 bottom-2 opacity-75 " + (theme.primary_light ? 'text-black' : 'text-white')} onClick={DeviceStore.toggleOrganisationInfo}>
            <InformationCircleIcon className="h-6"/>
          </button>
        </div>
      </div>
  )
}

export default inject("DeviceStore")(observer(organisationHeader));
