import React from "react";

function ModalButton({label, icon = null, href = null, onClick = null, theme = null, className = ''}){

  className = 'p-3 bg-blue-600 rounded-md font-bold flex w-full justify-between items-center ' + className + (theme.primary_light ? ' text-black' : ' text-white');

  if(href){
    return (
        <a className={className} href={href} target="_blank" rel="noreferrer" style={{backgroundColor: theme.primary_color}}>
          {label}
          {icon ? icon : null}
        </a>
    )
  }

  return (<button type="button" className={className} onClick={onClick} style={{backgroundColor: theme.primary_color}}>
    {label}
    {icon ? icon : null}
  </button>)
}

export default ModalButton;
