import {MailIcon, OfficeBuildingIcon, PhoneIcon, GlobeIcon} from "@heroicons/react/outline";
import React from "react";
import {useSpring, animated} from "@react-spring/web";

function OrganisationInfo ({DeviceStore}) {

  const {organisation_info, iframe, theme, t} = DeviceStore;

  const springs = useSpring({
    from: { y: 25, opacity: 0, },
    to: { y: 0, opacity: 1 },
  })

  const fade = useSpring({
    from: {opacity: 0, },
    to: {opacity: 1 },
  })

  return (
      <animated.div style={fade} className={'organisation-popup fixed top-0 right-0 bottom-0 left-0 bg-opacity-25 z-50 backdrop-blur-sm ' + (iframe ? '' : 'bg-black')}>
        <div className="absolute top-0 left-0 right-0 bottom-0" onClick={DeviceStore.toggleOrganisationInfo}/>
        <div className="p-4">
          <animated.div style={springs} className={"bg-white p-6 rounded-md relative z-20 shadow-lg mt-40 w-full max-w-sm mx-auto dark:bg-gray-900 dark:text-white " + (iframe ? ' border dark:border-gray-700' : ' dark:border dark:border-gray-700')}>
            <h3 className="text-xl font-bold mb-4">{organisation_info.name}</h3>
            <p className="organisation-company-number flex items-center mt-2">
              <OfficeBuildingIcon className="text-blue-600 h-5 mr-2" style={{color: theme?.primary_color}}/>
              {organisation_info.kvk}
            </p>
            {organisation_info.website ? <a href={organisation_info.website} target="_blank" rel="noreferrer" className="organisation-company-email flex items-center mt-2">
              <GlobeIcon className="text-blue-600 h-5 mr-2" style={{color: theme?.primary_color}}/>
              {organisation_info.website.replace('https://', '').replace('http://', '').replace(/^\/|\/$/g, '')}
            </a> : null }
            <a href={'mailto:'+organisation_info.email} className="organisation-company-email flex items-center mt-2">
              <MailIcon className="text-blue-600 h-5 mr-2" style={{color: theme?.primary_color}}/>
              {organisation_info.email}
            </a>
            {organisation_info.phone ? <a href={'tel:'+organisation_info.phone} className="organisation-company-phone flex items-center mt-2">
              <PhoneIcon className="text-blue-600 h-5 mr-2" style={{color: theme?.primary_color}}/>
              {organisation_info.phone}
            </a> : null}
            <button className="text-center w-full mt-4 border-t pt-4 text-gray-600 dark:text-gray-100 dark:border-gray-700" type="button" onClick={DeviceStore.toggleOrganisationInfo}>{t('close')}</button>
          </animated.div>
        </div>
      </animated.div>
  )
}

export default OrganisationInfo;
