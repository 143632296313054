import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {inject, observer, useLocalObservable} from "mobx-react";
import TicketField from "../components/form/TicketField";
import PrimaryButton from "../components/PrimaryButton";
import axios from "axios";

function WaitingList({DeviceStore}){

  const {organisation_slug, shop_id} = useParams();
  const navigate = useNavigate();

  const {eventData, apiUrl} = DeviceStore;

  const state = useLocalObservable(() => ({
    loading: false,
    sent: false,
    first_name: '',
    last_name: '',
    email: '',
    tickets: '',
  }));

  useEffect(() => {
    if(!eventData) {
      navigate(`/${organisation_slug}/${shop_id}`);
    }
    return;
  },[]);


  if(!eventData){
    return null;
  }

  const submit = async (e) => {

    e.preventDefault();

    if(state.loading) return;

    state.loading = true;

    try {
      await axios.post(apiUrl + `/shop/${organisation_slug}/${shop_id}/waiting-list`, {
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        tickets: state.tickets,
      });
      state.sent = true;
    } catch (e) {
      DeviceStore.setError(e.response?.data?.message || 'Er ging iets fout, probeer het later nog eens.')
    }

    state.loading = false;
  }

  return (
      <form onSubmit={submit}>
      <div className="bg-white border dark:border-gray-700 dark:bg-gray-900 dark:text-white mb-4 shadow rounded-md p-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          Wachtlijst
        </h3>
        <div className="mt-4 space-y-4">

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 dark:border-gray-700">
            <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-gray-300">
              <span className="form-field-label">
                Voornaam<span className="required text-red-500">*</span>
              </span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-sm">
              <TicketField
                  value={state.first_name}
                  onChange={({target}) => state.first_name = target.value}
                  field={{
                type: 'text',
                name: "first_name",
                required: true
              }}/>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 dark:border-gray-700">
            <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-gray-300">
              <span className="form-field-label">
                Achternaam<span className="required text-red-500">*</span>
              </span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-sm">
              <TicketField
                  value={state.last_name}
                  onChange={({target}) => state.last_name = target.value}
                  field={{
                type: 'text',
                name: "last_name",
                required: true
              }}/>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 dark:border-gray-700">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-gray-300">
              <span className="form-field-label">
                E-mailadres<span className="required text-red-500">*</span>
              </span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-sm">
              <TicketField
                  value={state.email}
                  onChange={({target}) => state.email = target.value}
                  field={{
                type: 'email',
                name: "email",
                required: true
              }}/>
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-4 dark:border-gray-700">
            <label htmlFor="tickets" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-gray-300">
              <span className="form-field-label">
                Gewenst aantal tickets<span className="required text-red-500">*</span>
              </span>
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 max-w-sm">
              <TicketField
                  value={state.tickets}
                  onChange={({target}) => state.tickets = target.value}
                  field={{
                type: 'number',
                name: "tickets",
                required: true
              }}/>
            </div>
          </div>

          <div className="border-t pt-4 dark:border-gray-700">
            {!state.sent ? <PrimaryButton loading={state.loading} type="submit" theme={eventData.theme} label="Inschrijven"/> :
            <div className="p-2 rounded font-bold px-4 bg-green-500 text-white">Inschrijving succesvol verstuurd!</div> }
          </div>
        </div>
      </div>
      </form>
  )

}

export default inject('DeviceStore')(observer(WaitingList));
