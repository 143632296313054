import React, {useEffect} from "react";
import {inject} from "mobx-react";
import {observer, useLocalObservable} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";

function Invite({DeviceStore}){

  const {organisation_slug, shop_id, invite_id} = useParams();
  const navigate = useNavigate();

  const state = useLocalObservable(() => ({
    loading: true,
    code: '',
    invalid: false,
    setCode(value){
      this.code = value;
    }
  }));

  useEffect(() => {
    init();
  },[]);

  const init = async () => {
    const queryParams = window.location.search;
    if(invite_id){
      DeviceStore.setInviteId(invite_id);
      navigate(`/${organisation_slug}/${shop_id}`+queryParams);
      return;
    }

    if(!DeviceStore.eventData) {
      state.loading = true;
    }
    await DeviceStore.getEvent(organisation_slug, shop_id);
    state.loading = false;
  }

  if (invite_id) return null;

  if(!DeviceStore.eventData){
    return null;
  }

  const submit = async () => {
    if(!state.code) return;
    state.loading = true;
    state.invalid = false;
    const result = await DeviceStore.getInviteByCode(organisation_slug, shop_id, state.code);
    state.invalid = !result;
    state.loading = false;
    if(!state.invalid){
      navigate(`/${organisation_slug}/${shop_id}`)
    }
  }

  let {theme, t} = DeviceStore;

  return (
      <form>
        <div>
          <div className="p-4 bg-white dark:bg-gray-900 rounded-md shadow-md border border-gray-200 dark:border-gray-700 space-y-4">
          <p className="mb-5 text-gray-700 dark:text-gray-100">{t('invite_code_desc')}</p>
            <div className="flex space-x-2">
              <input type="text" placeholder={t('access_code')} value={state.code} onChange={({target}) => state.setCode(target.value) } className="rounded-md border border-gray-300" required name="code"/>
              <button onClick={submit} disabled={state.loading} className={'next-btn bg-blue-600 p-4 py-2 rounded text-white ' + (state.loading ? 'opacity-50' : '')} style={{backgroundColor: theme.primary_color}} type="button">{t('next')}</button>
            </div>
            {state.invalid ? <p className="mt-2 text-red-500">{t('invalid_access_code')}</p> : null}
          </div>
        </div>
      </form>
    );
}

export default inject('DeviceStore')(observer(Invite));
