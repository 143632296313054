import DateInput from "../DateInput";
import React from "react";
import {inject} from "mobx-react";
import {observer} from "mobx-react-lite";

const ticketInput = ({DeviceStore, field, invalid, onChange, value, disabled}) => {

  if(!value){
    value = ''
  }

  const {t, locale, defaultLocale} = DeviceStore;

  if(field.type === 'select'){
    return (
        <select value={value} onChange={onChange} disabled={disabled ?? false} required={field.required} className={'max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:text-sm rounded-md dark:bg-gray-950 dark:text-white ' + (invalid ? 'border-red-500' : 'border-gray-300 dark:border-gray-700'  + ( (disabled ? ' cursor-not-allowed ' : '')))}>
          <option value="">{t('choose')}</option>
          {Object.entries(field.options).map(option => <option key={option[0]} value={option[0]}>{ option[1][locale] ?? option[1][defaultLocale] }</option>)}
        </select>
    )
  }

  if(field.type === 'date'){
    return <DateInput invalid={invalid} required={field.required} disabled={disabled ?? false} className={'sm:max-w-lg'} onChange={onChange} value={value} />
  }

  return <input value={value} onChange={onChange} id={field.name} type={field.type} disabled={disabled ?? false} required={field.required} className={'max-w-lg block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 rounded-md dark:text-white dark:bg-gray-950 ' + ( (disabled ? ' opacity-50 cursor-not-allowed ' : '')) + (invalid ? 'border-red-500' : 'border-gray-300 dark:border-gray-700')}/>
}

export default inject("DeviceStore")(observer(ticketInput));
